export const heroDataObj = (headingCopy, subHeadingCopy) => {
    const heroProps = {
        stylingOptions: {
            textShade: "Light",
            textAlignment: "Left",
            spacingBottom: "M",
            spacingTop: "M",
            bgGradient: "Blog Gradient",
        },
        heroImage: [
            {
                format: "png",
                public_id: "blog/category-hero/blog-hero-main_za0jzm",
                width: 774,
                height: 686,
            },
        ],
        heroHeading: {
            raw: {
                data: {},
                content: [
                    {
                        data: {},
                        content: [{ data: {}, marks: [], value: headingCopy, nodeType: "text" }],
                        nodeType: "heading-1",
                    },
                ],
                nodeType: "document",
            },
        },
        heroSubHeading: {
            raw: {
                data: {},
                content: [
                    {
                        data: {},
                        content: [
                            {
                                data: {},
                                marks: [],
                                value: subHeadingCopy,
                                nodeType: "text",
                            },
                        ],
                        nodeType: "heading-2",
                    },
                ],
                nodeType: "document",
            },
        },
        headerIcon: [
            {
                format: "svg",
                public_id: "blog/blog-icon-main_d1umza",
            },
        ],
        imageVertAlignment: "Center",
        imageHorzAlignment: "Right",
    };

    return heroProps;
};
