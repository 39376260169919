import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import SEO from "@v4/gatsby-theme-talend/src/components/Seo/Seo";
import CTA from "@v4/talend/src/components/cta/Cta";
import Image from "@v4/gatsby-theme-talend/src/components/Image/Image";
import { Helmet } from "react-helmet";
import { blogTranslations } from "../../translations/blog";
import { getLangPrefixedPath, getPrefixedPathByPageType } from "@v4/utils/pathPrefix";

import { HeroWithIcon } from "../../components/hero/HeroWithIcon";
import SingleBlogEntryItem from "../../components/singleBlogEntryItem/SingleBlogEntryItem";

// Styling
import * as blogHubStyles from "./blogLandingPage.module.css";
import classNames from "classnames/bind";

import { heroDataObj } from "../data/blogData/heroProps";

export const query = graphql`
    query($locale: String!) {
        contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
        categories: allContentfulBlogCategory(
            sort: { fields: [order, blog___featureLandingPage, blog___publishDate], order: [ASC, ASC, DESC] }
            filter: { node_locale: { eq: "en" }, unlisted: { ne: "True" } }
        ) {
            nodes {
                id
                title
                slug
                order
                icon {
                    # fluid (maxWidth: 100, transformations: "c_fit")
                    public_id
                    width
                    height
                    format
                }
                iconWhite {
                    # fluid (maxWidth: 100, transformations: "c_fit")
                    public_id
                    width
                    height
                    format
                }
                iconAltText
                node_locale
                # This field is created by using a query resolver in gatsby-node.js
                featuredBlogs {
                    id
                    title
                    slug
                    previewText
                    featureLandingPage
                    author {
                        name
                    }
                    slug
                    image {
                        # fluid (maxWidth: 900, transformations: "c_fit")
                        public_id
                        width
                        height
                        format
                    }
                    metaFields {
                        metaTitle
                        metaDescription
                        metaImage {
                            # fluid (maxWidth: 900, transformations: "c_fit")
                            public_id
                            width
                            height
                            format
                        }
                    }
                    imageAltText
                    languages
                    node_locale
                    publishDate
                }
            }
        }
    }
`;

export default function BlogLandingPage({ data, pageContext }) {
    const { locale } = pageContext;

    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    const styleProps = {};
    const cx = classNames.bind(blogHubStyles);

    const translations = blogTranslations[locale];
    // Hero fields
    // Define the hero props
    const heroProps = heroDataObj(translations.heroHeading, translations.heroSubHeading);

    // Ensure the raw content for the Heading fields is parsed into JSON (only once)
    if (typeof heroProps.heroHeading.raw !== "string")
        heroProps.heroHeading.raw = JSON.stringify(heroProps.heroHeading.raw);
    if (typeof heroProps.heroSubHeading.raw !== "string")
        heroProps.heroSubHeading.raw = JSON.stringify(heroProps.heroSubHeading.raw);

    // Build the Blog Category Listing
    const BlogCategoryListing = () => {
        // Also sort the categories by the order field again
        const categories = Object.values(data.categories.nodes);

        return categories.map((catData) => {
            const blogData = catData.featuredBlogs;

            // If there are no blogs for this category, just return null
            if (blogData?.length === 0) return null;

            // For when a logo is present
            const iconImage = catData.icon ? catData.icon[0] : false;
            const iconImageStyle = catData.icon
                ? {
                      width: "40px",
                      height: "auto",
                  }
                : null;
            const iconImageAltText = catData.iconAltText ?? "";

            return (
                <div key={catData.id} className={cx("categorySection")}>
                    <div className={cx("categoryHeading")}>
                        {iconImage && (
                            <div className={cx("categoryHeadingIconWrapper")}>
                                <Image
                                    className={cx("categoryHeadingIcon")}
                                    image={iconImage}
                                    imgStyle={iconImageStyle}
                                    alt={iconImageAltText}
                                />
                            </div>
                        )}
                        <h2 className={cx("categoryTitle")}>{catData.title}</h2>
                    </div>
                    <div className={cx("blogListing")}>
                        {blogData.map((blog) => (
                            <SingleBlogEntryItem {...blog} key={blog.id} />
                        ))}
                    </div>
                    <div className={cx("categoryCTA")}>
                        <CTA
                            title={translations.seeMore}
                            variant="Tertiary"
                            url={getPrefixedPathByPageType(catData.slug, "ContentfulBlogCategory")}
                        />
                    </div>
                </div>
            );
        });
    };

    return (
        <Layout
            headerMenu={data.contentfulMenu}
            footerMenu={footerData}
            notificationBar={false}
            pageStyleProps={styleProps}
        >
            <SEO
                title={translations.metaTitle}
                pathname={getLangPrefixedPath("/blog", locale)}
                description={translations.metaDescription}
                type={`BlogPost`}
            />
            <Helmet bodyAttributes={{ class: "darkOpaque" }} />
            <HeroWithIcon
                title={translations.heroHeading}
                gradientType={"bgGradientBlogGradient"}
                description={translations.heroSubHeading}
                heroImage={heroProps.heroImage[0] ?? false}
                heroImageStyle={{
                    width: "387px",
                    height: "auto",
                }}
                iconImage={heroProps.headerIcon[0] ?? false}
                iconImageStyle={{
                    width: "48px",
                    height: "auto",
                }}
                iconImageAltText={""}
            />
            <section className={cx("container")}>
                <BlogCategoryListing />
            </section>
        </Layout>
    );
}
